exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-example-js": () => import("./../../../src/pages/example.js" /* webpackChunkName: "component---src-pages-example-js" */),
  "component---src-pages-games-bmp-2-js": () => import("./../../../src/pages/games/bmp2.js" /* webpackChunkName: "component---src-pages-games-bmp-2-js" */),
  "component---src-pages-games-bs-js": () => import("./../../../src/pages/games/bs.js" /* webpackChunkName: "component---src-pages-games-bs-js" */),
  "component---src-pages-games-dragonsword-js": () => import("./../../../src/pages/games/dragonsword.js" /* webpackChunkName: "component---src-pages-games-dragonsword-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-lok-1-js": () => import("./../../../src/pages/games/lok1.js" /* webpackChunkName: "component---src-pages-games-lok-1-js" */),
  "component---src-pages-games-piko-4-js": () => import("./../../../src/pages/games/piko4.js" /* webpackChunkName: "component---src-pages-games-piko-4-js" */),
  "component---src-pages-games-tr-1-js": () => import("./../../../src/pages/games/tr1.js" /* webpackChunkName: "component---src-pages-games-tr-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-socials-js": () => import("./../../../src/pages/socials.js" /* webpackChunkName: "component---src-pages-socials-js" */)
}

